var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "OperationRideTable"
  }, [_c('BaseDashboardTable', {
    ref: "dashboardTable",
    attrs: {
      "columns": _vm.columns,
      "items": _vm.formattedOperationRecords,
      "sort": _vm.sortState,
      "empty-message": 'Keine Fahrten vorhanden',
      "use-hierarchical-rows": true,
      "searchable-columns": ['licenseNumber'],
      "search-placeholder": 'Fahrten durchsuchen...'
    },
    on: {
      "sort-changed": _vm.handleSortChanged,
      "toggle-expand": _vm.handleToggleExpand
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h3', [_vm._v("Fahrzeuge")])];
      },
      proxy: true
    }])
  }), _vm.formattedOperationRecords.length >= 120 && _vm.formattedOperationRecords.length < _vm.operationRecords.length ? _c('Button', {
    staticClass: "Button--LoadMore",
    on: {
      "onClick": _vm.handleLoadMore
    }
  }, [_vm._v(" Mehr Laden ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }