var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "OperationRideTableCar"
  }, [_c('BaseDashboardTable', {
    ref: "dashboardTable",
    attrs: {
      "columns": _vm.columns,
      "items": _vm.formattedOperationRecords,
      "sort": _vm.sortState,
      "empty-message": 'Keine Fahrten vorhanden',
      "use-hierarchical-rows": true,
      "searchable-columns": ['driver', 'licenseNumber'],
      "search-placeholder": 'Fahrten durchsuchen...'
    },
    on: {
      "sort-changed": _vm.handleSortChanged,
      "toggle-expand": _vm.handleToggleExpand
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h3', [_vm._v("Fahrten")])];
      },
      proxy: true
    }, {
      key: "column-driver",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item._isGap ? _c('div', {
          staticClass: "gap-indicator"
        }, [_vm._v(" " + _vm._s("".concat(item.value)) + " ")]) : _c('span', [_vm._v(_vm._s(item.driver))])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }