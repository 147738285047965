<template>
    <div class="OperationRideTable">
        <BaseDashboardTable
            ref="dashboardTable"
            :columns="columns"
            :items="formattedOperationRecords"
            :sort="sortState"
            :empty-message="'Keine Fahrten vorhanden'"
            :use-hierarchical-rows="true"
            :searchable-columns="['licenseNumber']"
            :search-placeholder="'Fahrten durchsuchen...'"
            @sort-changed="handleSortChanged"
            @toggle-expand="handleToggleExpand"
        >
            <template #title>
                <h3>Fahrzeuge</h3>
            </template>
        </BaseDashboardTable>
        <Button
            v-if="
                formattedOperationRecords.length >= 120 &&
                    formattedOperationRecords.length < operationRecords.length
            "
            class="Button--LoadMore"
            @onClick="handleLoadMore"
        >
            Mehr Laden
        </Button>
    </div>
</template>

<script>
import BaseDashboardTable from '@/components/BaseDashboardTable';
import Button from '@/components/widgets/Button';
import { priceToEuroString, distanceToString } from '@/lib/helper';
import { format } from 'date-fns';

export default {
    name: 'OperationRideTable',
    components: {
        BaseDashboardTable,
        Button,
    },
    props: {
        operationRecords: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            cursor: 120, // Pagination cursor
            sortState: {
                column: 'licenseNumber',
                ascending: true,
            },
            columns: [
                {
                    key: 'licenseNumber',
                    label: 'Kennz.',
                    tooltip: 'Kennzeichen',
                    width: '10%',
                },
                {
                    key: 'kmWithoutPrivate',
                    label: 'KM ohne P.',
                    tooltip: 'Gefahrene Kilometer ohne Privatfahrten',
                },
                {
                    key: 'kmWithPrivate',
                    label: 'KM mit P.',
                    tooltip: 'Gefahrene Kilometer mit Privatfahrten',
                },
                {
                    key: 'privateKm',
                    label: 'P. KM',
                    tooltip: 'Privatfahrten Kilometer',
                },
                {
                    key: 'hiredDistance',
                    label: 'Besetzt KM',
                    tooltip: 'Besetzt Kilometer',
                },
                {
                    key: 'emptyKm',
                    label: 'Leer KM',
                    tooltip: 'Leerfahrt Kilometer',
                },
                {
                    key: 'revenue',
                    label: 'Umsatz',
                    tooltip: 'Gesamter Umsatz',
                },
                {
                    key: 'revenuePerKm',
                    label: '€/KM',
                    tooltip: 'Umsatz pro Kilometer',
                },
                {
                    key: 'tripsCount',
                    label: 'Touren',
                    tooltip: 'Anzahl der Touren',
                },
                {
                    key: 'shifts6hOrMore',
                    label: 'Schichten 6h+',
                    tooltip: 'Schichten mit mehr als 6 Stunden',
                },
                {
                    key: 'occupancyRate',
                    label: 'Besetzt/Gesamt',
                    tooltip: 'Verhältnis besetzter Kilometer zu Gesamtkilometern',
                },
            ],
            expandedRecordIds: [],
        };
    },
    computed: {
        formattedOperationRecords() {
            // First group and format the parent records
            const groupedData = this.groupAndAggregate(this.operationRecords);
            const formattedData = [];

            // Build hierarchical data with children
            groupedData.forEach(parent => {
                formattedData.push(parent);

                // Add child rows if expanded
                if (parent._expanded && parent.originalData.children) {
                    parent.originalData.children.forEach((childRecord, childIndex) => {
                        // Create formatted child row
                        const childRow = this.formatChildRecord(
                            childRecord,
                            parent._id,
                            childIndex,
                        );
                        formattedData.push(childRow);
                    });
                }
            });

            // Apply sorting while maintaining hierarchy
            const sortedData = this.sortHierarchicalData(formattedData);
            return sortedData.slice(0, this.cursor);
        },
    },
    methods: {
        handleSortChanged(sort) {
            this.sortState = {
                column: sort.column,
                ascending: sort.ascending,
            };
        },
        handleToggleExpand({ item }) {
            const recordId = item._id;
            const index = this.expandedRecordIds.indexOf(recordId);
            if (index >= 0) {
                this.expandedRecordIds.splice(index, 1);
            } else {
                this.expandedRecordIds.push(recordId);
            }
        },
        formatChildRecord(record, parentId, index) {
            // Format a single record as a child row
            const totalDistance = record.mileageEnd - record.mileageStart;
            const driverName = record.driver?.name || 'Unbekannt';
            const startDate = format(new Date(record.startAt), 'dd.MM.yy HH:mm');
            const endDate = format(new Date(record.endAt), 'dd.MM.yy HH:mm');

            return {
                licenseNumber: record.licenseNumber,
                driver: driverName,
                startAt: startDate,
                endAt: endDate,
                kmWithoutPrivate: distanceToString(totalDistance),
                hiredDistance: distanceToString(record.hiredDistance),
                emptyKm: distanceToString(totalDistance - record.hiredDistance),
                revenue: priceToEuroString(record.finalTotalAmount),
                tripsCount: record.tripsCount,

                // Raw values for sorting
                _licenseNumber: record.licenseNumber.toLowerCase(),
                _startAt: new Date(record.startAt),
                _endAt: new Date(record.endAt),
                _kmWithoutPrivate: totalDistance,
                _hiredDistance: record.hiredDistance,
                _emptyKm: totalDistance - record.hiredDistance,
                _revenue: record.finalTotalAmount,
                _tripsCount: record.tripsCount,

                // Metadata
                originalRecord: record,
                _id: `${parentId}-child-${index}`,
                _parentId: parentId,
                _isChild: true,
                _level: 1,
                _hasChildren: false,
            };
        },
        sortHierarchicalData(data) {
            const { column, ascending } = this.sortState;
            const sortKey = column.startsWith('_') ? column : `_${column}`;

            // Separate parents and children
            const parentRows = data.filter(row => !row._isChild);
            const childRowsByParent = {};

            data.forEach(row => {
                if (row._isChild && row._parentId) {
                    if (!childRowsByParent[row._parentId]) {
                        childRowsByParent[row._parentId] = [];
                    }
                    childRowsByParent[row._parentId].push(row);
                }
            });

            // Sort parent rows
            parentRows.sort((a, b) => {
                const valueA = a[sortKey] !== undefined ? a[sortKey] : '';
                const valueB = b[sortKey] !== undefined ? b[sortKey] : '';
                return this.compareValues(valueA, valueB, ascending);
            });

            // Sort child rows for each parent
            Object.keys(childRowsByParent).forEach(parentId => {
                childRowsByParent[parentId].sort((a, b) => {
                    const valueA = a[sortKey] !== undefined ? a[sortKey] : '';
                    const valueB = b[sortKey] !== undefined ? b[sortKey] : '';
                    return this.compareValues(valueA, valueB, ascending);
                });
            });

            // Rebuild sorted array
            const sortedData = [];
            parentRows.forEach(parent => {
                sortedData.push(parent);
                if (parent._expanded && childRowsByParent[parent._id]) {
                    sortedData.push(...childRowsByParent[parent._id]);
                }
            });

            return sortedData;
        },
        compareValues(valueA, valueB, ascending) {
            if (valueA instanceof Date && valueB instanceof Date) {
                return ascending ? valueA - valueB : valueB - valueA;
            }
            if (typeof valueA === 'string' && typeof valueB === 'string') {
                return ascending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            }
            return ascending ? valueA - valueB : valueB - valueA;
        },
        groupAndAggregate(records) {
            const grouped = records.reduce((acc, record) => {
                const key = record.licenseNumber;
                // Initialize the group if it doesn't exist
                if (!acc[key]) {
                    acc[key] = {
                        licenseNumber: key,
                        minMileageStart: record.mileageStart,
                        maxMileageEnd: record.mileageEnd,
                        hiredDistance: 0,
                        kmWithoutPrivate: 0,
                        finalTotalAmount: 0,
                        tripsCount: 0,
                        shifts6hOrMore: 0,
                        children: [],
                    };
                }

                // Store the original record for child rows
                acc[key].children.push(record);

                // Update min/max values and accumulators
                acc[key].minMileageStart = Math.min(acc[key].minMileageStart, record.mileageStart);
                acc[key].maxMileageEnd = Math.max(acc[key].maxMileageEnd, record.mileageEnd);
                acc[key].hiredDistance += record.hiredDistance;
                acc[key].kmWithoutPrivate += record.mileageEnd - record.mileageStart;
                acc[key].finalTotalAmount += record.finalTotalAmount;
                acc[key].tripsCount += record.tripsCount;

                // Count shifts with 6+ hours
                const shiftDurationMs = new Date(record.endAt) - new Date(record.startAt);
                if (shiftDurationMs >= 6 * 60 * 60 * 1000) {
                    acc[key].shifts6hOrMore += 1;
                }

                return acc;
            }, {});

            // Convert to array format for the table
            return Object.values(grouped).map(group => {
                const recordId = `record-${group.licenseNumber}`;
                const isExpanded = this.expandedRecordIds.includes(recordId);

                const kmWithPrivate = group.maxMileageEnd - group.minMileageStart;
                const privateKm = kmWithPrivate - group.kmWithoutPrivate;
                const emptyKm = kmWithPrivate - group.hiredDistance;
                const occupancyRate = (group.hiredDistance / kmWithPrivate) * 100 || 0;

                return {
                    licenseNumber: group.licenseNumber,
                    kmWithoutPrivate: distanceToString(group.kmWithoutPrivate),
                    kmWithPrivate: distanceToString(kmWithPrivate),
                    privateKm: distanceToString(privateKm),
                    hiredDistance: distanceToString(group.hiredDistance),
                    emptyKm: distanceToString(emptyKm),
                    revenue: priceToEuroString(group.finalTotalAmount),
                    revenuePerKm: priceToEuroString(
                        group.finalTotalAmount / Math.max(kmWithPrivate / 1000, 1),
                    ),
                    tripsCount: group.tripsCount,
                    shifts6hOrMore: group.shifts6hOrMore,
                    occupancyRate: `${occupancyRate.toFixed(2)}%`,

                    // Raw values for sorting
                    _licenseNumber: group.licenseNumber.toLowerCase(),
                    _kmWithoutPrivate: group.kmWithoutPrivate,
                    _kmWithPrivate: kmWithPrivate,
                    _privateKm: privateKm,
                    _hiredDistance: group.hiredDistance,
                    _emptyKm: emptyKm,
                    _revenue: group.finalTotalAmount,
                    _revenuePerKm: group.finalTotalAmount / Math.max(kmWithPrivate / 1000, 1),
                    _tripsCount: group.tripsCount,
                    _shifts6hOrMore: group.shifts6hOrMore,
                    _occupancyRate: occupancyRate,

                    // Metadata
                    originalData: group,
                    _id: recordId,
                    _hasChildren: group.children && group.children.length > 0,
                    _expanded: isExpanded,
                    _isChild: false,
                    _level: 0,
                };
            });
        },
        handleLoadMore() {
            this.cursor += 120;
        },
    },
};
</script>

<style lang="scss" scoped>
.OperationRideTable {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    :deep(.BaseDashboardTable) {
        height: 100%;
        display: flex;
        flex-direction: column;

        .BaseDashboardTable-container {
            flex: 1;
            min-height: 200px;

            table {
                table-layout: fixed;

                tbody {
                    tr {
                        &.child-row {
                            td {
                                width: inherit;
                                min-width: inherit;
                                max-width: inherit;

                                &:first-child {
                                    padding-left: 40px; // Add indent for child rows
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .Button--LoadMore {
        margin-top: 16px;
        width: 100%;
    }
}
</style>
