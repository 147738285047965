<template>
    <div class="OperationRideTableCar">
        <BaseDashboardTable
            ref="dashboardTable"
            :columns="columns"
            :items="formattedOperationRecords"
            :sort="sortState"
            :empty-message="'Keine Fahrten vorhanden'"
            :use-hierarchical-rows="true"
            :searchable-columns="['driver', 'licenseNumber']"
            :search-placeholder="'Fahrten durchsuchen...'"
            @sort-changed="handleSortChanged"
            @toggle-expand="handleToggleExpand"
        >
            <template #title>
                <h3>Fahrten</h3>
            </template>

            <template #column-driver="{ item }">
                <div v-if="item._isGap" class="gap-indicator">
                    {{ `${item.value}` }}
                </div>
                <span v-else>{{ item.driver }}</span>
            </template>
        </BaseDashboardTable>
    </div>
</template>

<script>
import BaseDashboardTable from '@/components/BaseDashboardTable';
import { format } from 'date-fns';
import { priceToEuroString, distanceToString } from '@/lib/helper';

export default {
    name: 'OperationRideTableCar',
    components: {
        BaseDashboardTable,
    },
    props: {
        operationRecords: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            hasOnlyOneCarInRecords: false,
            sortState: {
                column: 'endAt',
                ascending: false,
            },
            columns: [
                {
                    key: 'driver',
                    label: 'Fahrer',
                    tooltip: 'Fahrer Name',
                },
                {
                    key: 'licenseNumber',
                    label: 'Kennz.',
                    tooltip: 'Kennzeichen',
                },
                {
                    key: 'startAt',
                    label: 'Beginn',
                    tooltip: 'Beginn der Fahrt',
                },
                {
                    key: 'endAt',
                    label: 'Ende',
                    tooltip: 'Ende der Fahrt',
                },
                {
                    key: 'duration',
                    label: 'Dauer',
                    tooltip: 'Dauer der Fahrt',
                },
                {
                    key: 'mileageStart',
                    label: 'KM-Abfahrt',
                    tooltip: 'Kilometerstand bei Abfahrt',
                },
                {
                    key: 'mileageEnd',
                    label: 'KM-Ankunft',
                    tooltip: 'Kilometerstand bei Ankunft',
                },
                {
                    key: 'totalDistance',
                    label: 'Gesamt KM',
                    tooltip: 'Gesamt gefahrene Kilometer',
                },
                {
                    key: 'hiredDistance',
                    label: 'Besetzt KM',
                    tooltip: 'Mit Fahrgast gefahrene Kilometer',
                },
                {
                    key: 'tripsCount',
                    label: 'Touren',
                    tooltip: 'Anzahl der Touren',
                },
                {
                    key: 'eurPerKm',
                    label: 'Eur/KM',
                    tooltip: 'Euro pro Kilometer',
                },
                {
                    key: 'revenue',
                    label: 'Umsatz (€)',
                    tooltip: 'Gesamtumsatz',
                },
            ],
            expandedRecordIds: [],
        };
    },
    computed: {
        formattedOperationRecords() {
            if (!this.operationRecords?.length) return [];

            // Format and sort the records
            const formattedData = this.formatOperationRecords();
            return this.sortHierarchicalData(formattedData);
        },
    },
    watch: {
        operationRecords: {
            immediate: true,
            handler() {
                this.checkIfSingleCar();
            },
        },
    },
    methods: {
        checkIfSingleCar() {
            // Determine if all records are for the same car with sequential numbers
            this.hasOnlyOneCarInRecords = this.operationRecords.every((entry, index, array) => {
                if (index === 0) return true;
                const prevEntry = array[index - 1];
                return prevEntry.licenseNumber === entry.licenseNumber;
            });
        },
        formatOperationRecords() {
            const formattedData = [];
            let sumOfAllGaps = 0;

            // Process each operation record
            this.operationRecords.forEach((record, index) => {
                // Create the main operation record row
                const recordId = `record-${record.uuid || index}`;
                const isExpanded = this.expandedRecordIds.includes(recordId);
                console.log(index, this.hasOnlyOneCarInRecords);
                // Add gap row if needed (before the current record)
                if (index > 0 && this.hasOnlyOneCarInRecords) {
                    const previousRecord = this.operationRecords[index - 1];

                    const gap = previousRecord.mileageStart - record.mileageEnd;
                    if (gap < 0) {
                        console.log(gap);
                    }

                    // Show a gap row for both positive gaps (private kilometers) and device resets
                    if (gap > 0) {
                        // Create gap row that's compatible with BaseDashboardTable
                        const gapRow = {
                            // Special flags for our custom rendering
                            _isGap: true,
                            // Value for display (only relevant for positive gaps)
                            value: distanceToString(gap),
                            // Populated data for all columns to avoid undefined errors
                            driver: `Privat: ${distanceToString(gap)}`,
                            licenseNumber: record.licenseNumber,
                            startAt: '-',
                            endAt: '-',
                            duration: '-',
                            mileageStart: '-',
                            mileageEnd: '-',
                            totalDistance: '-',
                            hiredDistance: '-',
                            tripsCount: '-',
                            eurPerKm: '-',
                            revenue: '-',

                            // Required metadata for BaseDashboardTable
                            _id: `gap-${recordId}`,
                            _level: 0,
                            _hasChildren: false,
                            _gap: gap, // Don't count device resets in total gap sum
                        };

                        formattedData.push(gapRow);
                        sumOfAllGaps += gap;
                    }
                }

                const operationRow = {
                    driver: record.driver?.name || '',
                    licenseNumber: record.licenseNumber,
                    startAt: format(new Date(record.startAt), 'dd.MM.yy HH:mm'),
                    endAt: format(new Date(record.endAt), 'dd.MM.yy HH:mm'),
                    duration: this.formatDuration(record.startAt, record.endAt),
                    mileageStart: distanceToString(record.mileageStart),
                    mileageEnd: distanceToString(record.mileageEnd),
                    totalDistance: distanceToString(record.totalDistance),
                    hiredDistance: distanceToString(record.hiredDistance),
                    tripsCount: record.tripsCount,
                    eurPerKm: priceToEuroString(
                        record.finalTotalAmount / Math.max(record.totalDistance / 1000, 1),
                    ),
                    revenue: priceToEuroString(record.finalTotalAmount),

                    // Raw values for sorting
                    _driver: (record.driver?.name || '').toLowerCase(),
                    _licenseNumber: record.licenseNumber.toLowerCase(),
                    _startAt: new Date(record.startAt),
                    _endAt: new Date(record.endAt),
                    _duration: this.getDurationInMinutes(record.startAt, record.endAt),
                    _mileageStart: record.mileageStart,
                    _mileageEnd: record.mileageEnd,
                    _totalDistance: record.totalDistance,
                    _hiredDistance: record.hiredDistance,
                    _tripsCount: record.tripsCount,
                    _eurPerKm: record.finalTotalAmount / Math.max(record.totalDistance / 1000, 1),
                    _revenue: record.finalTotalAmount,

                    // Metadata
                    originalRecord: record,
                    _id: recordId,
                    _hasChildren: record.trips && record.trips.length > 0,
                    _expanded: isExpanded,
                    _isChild: false,
                    _level: 0,
                    _isGap: false,
                };

                formattedData.push(operationRow);

                // Add trip rows if expanded
                if (isExpanded && record.trips && record.trips.length > 0) {
                    record.trips.forEach((trip, tripIndex) => {
                        const tripRow = {
                            // Only include relevant columns for trip rows
                            driver: '', // Empty for child rows
                            licenseNumber: '',
                            startAt: format(new Date(trip.startAt), 'dd.MM.yy HH:mm'),
                            endAt: format(new Date(trip.endAt), 'dd.MM.yy HH:mm'),
                            duration: this.formatDuration(trip.startAt, trip.endAt),
                            mileageStart: '',
                            mileageEnd: '',
                            totalDistance: distanceToString(trip.totalDistance),
                            hiredDistance: distanceToString(trip.hiredDistance),
                            tripsCount: '',
                            eurPerKm: priceToEuroString(
                                trip.finalTotalAmount / Math.max(trip.totalDistance / 1000, 1),
                            ),
                            revenue: priceToEuroString(trip.finalTotalAmount),

                            // Raw values for sorting
                            _startAt: new Date(trip.startAt),
                            _endAt: new Date(trip.endAt),
                            _totalDistance: trip.totalDistance,
                            _hiredDistance: trip.hiredDistance,
                            _eurPerKm:
                                trip.finalTotalAmount / Math.max(trip.totalDistance / 1000, 1),
                            _revenue: trip.finalTotalAmount,

                            // Metadata
                            originalTrip: trip,
                            _id: `${recordId}-trip-${tripIndex}`,
                            _parentId: recordId,
                            _isChild: true,
                            _level: 1,
                            _hasChildren: false,
                            _isGap: false,
                        };

                        formattedData.push(tripRow);
                    });
                }
            });

            // Emit the sum of gaps
            this.$emit('onSumOfAllGaps', sumOfAllGaps);

            return formattedData;
        },
        formatDuration(startAt, endAt) {
            const durationMs = new Date(endAt) - new Date(startAt);
            const hours = Math.floor(durationMs / (1000 * 60 * 60));
            const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
            return `${hours}h ${minutes}m`;
        },
        getDurationInMinutes(startAt, endAt) {
            return (new Date(endAt) - new Date(startAt)) / (1000 * 60);
        },
        handleSortChanged(sort) {
            this.$toasted.show('Sortierung ist hier fix auf Ende', { type: 'info' });
            // Uncomment to enable sorting
            // this.sortState = {
            //     column: sort.column,
            //     ascending: sort.ascending,
            // };
        },
        sortHierarchicalData(data) {
            // Separate normal rows, gap rows, and child rows
            const normalRows = data.filter(item => !item._isGap && !item._isChild);
            const gapRows = data.filter(item => item._isGap);
            const childRowsByParent = {};

            data.forEach(row => {
                if (row._isChild && row._parentId) {
                    if (!childRowsByParent[row._parentId]) {
                        childRowsByParent[row._parentId] = [];
                    }
                    childRowsByParent[row._parentId].push(row);
                }
            });

            // Sort normal rows by endAt (descending by default)
            normalRows.sort((a, b) => {
                return this.sortState.ascending ? a._endAt - b._endAt : b._endAt - a._endAt;
            });

            // Sort child rows for each parent
            Object.keys(childRowsByParent).forEach(parentId => {
                childRowsByParent[parentId].sort((a, b) => {
                    return this.sortState.ascending ? a._endAt - b._endAt : b._endAt - a._endAt;
                });
            });

            // Rebuild the sorted array with gaps in appropriate positions
            const sortedData = [];
            let previousNormalRow = null;

            normalRows.forEach(row => {
                // If we have gaps and this is a single car view, place gap rows between records
                if (this.hasOnlyOneCarInRecords && previousNormalRow && gapRows.length > 0) {
                    // Find the gap between the previous and current record
                    const gapRow = gapRows.find(gap => {
                        const gapId = `gap-${row._id}`;
                        return gap._id === gapId;
                    });

                    if (gapRow) {
                        sortedData.push(gapRow);
                    }
                }

                // Add the main row
                sortedData.push(row);

                // Add child rows if expanded
                if (row._expanded && childRowsByParent[row._id]) {
                    sortedData.push(...childRowsByParent[row._id]);
                }

                previousNormalRow = row;
            });

            return sortedData;
        },
        handleToggleExpand({ item }) {
            if (item._isGap) return; // Don't allow expanding gap rows

            const recordId = item._id;
            const index = this.expandedRecordIds.indexOf(recordId);
            if (index >= 0) {
                this.expandedRecordIds.splice(index, 1);
            } else {
                this.expandedRecordIds.push(recordId);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.OperationRideTableCar {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    :deep(.BaseDashboardTable) {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .gap-indicator {
        padding: 8px;
        text-align: center;
        width: 100%;
        font-style: italic;
        color: var(--color-text-light);
        background-color: #ffe6c8;

        // Make the gap indicator span the entire row visually
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &.device-reset {
            color: var(--color-warning-dark, #e65100);
            font-weight: 500;
        }
    }
}
</style>
